<template>
  <div class="recreation-page">
    <!-- <top-box :option="4"></top-box> -->
    <div class="bannar">
      <!-- <img src="../../image/bg.png"> -->
      <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
      <img :src="bannerSrc" v-if="bannerType" />
      <video
        v-else
        muted="muted"
        id="startvideo"
        class="video"
        :src="bannerSrc"
        type="video/mp4"
        autoplay="autoplay"
        controls="controls"
        loop="-1"
      >
        <p>你的浏览器不支持video标签.</p>
      </video>
      <div class="overlay"></div>
      <div class="bannar-info">
        <div class="bannar-title">合作开发</div>
        <div class="bannar-detail">{{ bannerTitle }}</div>
      </div>
    </div>
    <main>
      <div v-for="(item, index) in dataList" :key="index" class="main-item">
        <!-- <img src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/77be7dee7f5944e492c18c8d9cd15bb4_mergeImage.png"> -->
        <div class="main-item-img">
          <img :src="item.imgSrc" :title="item.name" />
        </div>
        <div class="main-item-info">
          <div class="left">
            {{ item.sketch }}
            <!-- 这里是泛娱乐内容描述这里是泛娱乐内容描述这里是泛娱乐内容描述，这里是泛娱乐内容描述这里是泛娱乐内容描述这里是泛娱乐内容描述这里是泛娱乐内容描述，这里是泛娱乐内容描述这里是泛娱乐内容描述这里是泛娱乐内容描述，这里是泛娱乐内容描述这里是泛娱乐内容描述。 -->
          </div>
          <div class="right">
            <div class="title">{{ item.name }}</div>
            <div class="detail" @click="detailsClick(item.id)">了解更多</div>
          </div>
        </div>
      </div>
      <div class="empty" v-if="dataList.length == 0">
        <div class="empty-bg"></div>
        <div class="empty-content">暂无数据~</div>
      </div>
    </main>
    <!-- <bottom-box></bottom-box> -->
  </div>
</template>
<script>
import { getBanner, getCooperation } from "../../../api/homeApi";
export default {
  data() {
    return {
      bannerSrc: "",
      bannerType: 1, //1.图片 0.视频
      bannerTitle: "",
      dataList: [],
    };
  },
  created() {
    this.getBanner();
    this.getExperience();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
    getBanner() {
      getBanner({
        plate: 6,
      }).then((res) => {
        if (res.code == 0) {
          if (res.infos.length > 0) {
            if (this._isMobile()) {
              this.bannerType = 1;
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].mobileImage)[0].raw
              );
            } else {
              let arr = JSON.parse(res.infos[0].image);
              if (
                [
                  "image/png", // .png
                  "image/jpeg", // .jpg .jpeg
                  "image/x-ms-bmp", // .bmp
                  "image/gif", // .gif
                  "image/tiff", // .tif .tiff
                ].includes(arr[0].type)
              ) {
                this.bannerType = 1;
              } else {
                this.bannerType = 0;
              }
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].image)[0].raw
              );
            }
            this.bannerTitle = res.infos[0].content;
          }
        }
      });
    },
    getExperience() {
      getCooperation({
        page: 1,
        limit: 4,
      }).then((res) => {
        if (res.code == 0) {
          this.dataList = res.page.list.map((item) => {
            item.imgSrc = this.formatImageUrl(JSON.parse(item.image)[0].raw);
            return item;
          });
        }
      });
    },
    detailsClick(id) {
      this.$router.push({ path: `/cooperate-details/${id}` });
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and(max-width: 900px) {
  .recreation-page {
    .bannar {
      width: 100%;
      // height: 5.5rem;
      margin-top: 20px;
      background: #666;
      position: relative;
      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 9;
      }
      img {
        vertical-align: middle;
        height: 30vw;
        width: 100%;
      }
      .bannar-info {
        position: absolute;
        top: 20%;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 0 20px;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    main {
      box-sizing: border-box;
      // width:1200px;
      padding: 40px 20px;
      background-color: #fff;
      margin: 0 auto;
      .main-item:nth-last-child(1) {
        margin-bottom: 0.15rem;
      }
      .main-item {
        margin-bottom: 0.95rem;
        img {
          width: 100%;
          height: 4.6rem;
          display: block;
        }
        .main-item-info {
          display: flex;
          justify-content: space-between;
          padding-top: 0.3rem;
          .left {
            width: 6rem;
            line-height: 2;
            color: #666;
            font-size: 20px;
          }
          .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .title {
              font-size: 28px;
              color: #333;
              margin-bottom: 0.3rem;
            }
            .detail {
              cursor: pointer;
              font-size: 24px;
              color: #333;
              padding: 0 0.3rem;
              position: relative;
              height: 0.26rem;
              line-height: 0.26rem;
              width: 180px;
            }
            .detail::after {
              content: "";
              position: absolute;
              height: 0.5rem;
              width: 0.02rem;
              background-color: #ee6a23;
              left: 0;
              top: -0.12rem;
            }
            .detail::before {
              content: "";
              position: absolute;
              height: 0.5rem;
              width: 0.02rem;
              background-color: #ee6a23;
              right: 0;
              top: -0.12rem;
            }
          }
        }
      }
    }
  }
}
@media screen and(min-width: 900px) {
  .recreation-page {
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      position: relative;
      img {
        vertical-align: middle;
        height: 550px;
        width: 100%;
      }
      .bannar-info {
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 9;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    main {
      box-sizing: border-box;
      width: 1200px;
      padding: 0.8rem 0rem;
      background-color: #fff;
      margin: 0 auto;
      .main-item:nth-last-child(1) {
        margin-bottom: 0.15rem;
      }
      .main-item {
        margin-bottom: 0.95rem;
        .main-item-img {
          width: 100%;
          height: 4.6rem;
          overflow: hidden;
          img {
            width: 100%;
            height: 4.6rem;
            display: block;
            transition: 0.5s all;
          }
          img:hover {
            transition: 0.5s all;
            transform: scale(1.05);
          }
        }
        .main-item-info {
          display: flex;
          justify-content: space-between;
          padding-top: 0.3rem;
          .left {
            width: 6rem;
            line-height: 2;
            color: #666;
            font-size: 0.12rem;
          }
          .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .title {
              font-size: 0.24rem;
              color: #333;
              margin-bottom: 0.3rem;
            }
            .detail {
              cursor: pointer;
              font-size: 0.18rem;
              color: #333;
              padding: 10px 0.3rem;
              position: relative;
              // height: .26rem;
              // line-height: .26rem;
              width: 1.4rem;
              border-left: 2px solid #ee6a23;
              border-right: 2px solid #ee6a23;
              border-top: 2px solid transparent;
              border-bottom: 2px solid transparent;
              transition: 0.5s all;
            }
            .detail:hover {
              border-top: 2px solid #ee6a23;
              border-bottom: 2px solid #ee6a23;
            }
            // .detail::after {
            //     content: '';
            //     position: absolute;
            //     height: .5rem;
            //     width: .02rem;
            //     background-color: #EE6A23;
            //     left: 0;
            //     top: -.12rem;
            // }
            // .detail::before {
            //     content: '';
            //     position: absolute;
            //     height: .5rem;
            //     width: .02rem;
            //     background-color: #EE6A23;
            //     right: 0;
            //     top: -.12rem;
            // }
          }
        }
      }
    }
  }
}
</style>